<template>
  <div class="index">
    <div class="fix_box" v-if="openbox">
      <div class="tx_box">
        <div class="img_box">
          <img class="imgin" :src="merchant.weChat.headImg" />
        </div>
        <div class="tx_title">已绑定的微信号昵称为:{{ merchant.weChat.nickname }}</div>
        <div class="tx_btn" @click="openbox = false">已知晓</div>
      </div>
    </div>
    <div class="banner">
      <div class="maintitle">商户名：{{ merchant.merchantName }}</div>
      <div class="wxnum">
        微信号：{{ merchant.weChat ? "已绑定" : "暂未绑定" }}
      </div>
      <div class="dwbox" v-if="advance == null">
        <div class="dw_left">
          <div class="money">¥ {{ balance ? balance : 0 }}</div>
          <div class="ms">当前余额</div>
        </div>
        <div class="dw_center"></div>
        <div class="dw_left">
          <div class="money">¥ {{ (income && isBelongCanAgent) ? ((isBelongCanAgent == 'y') ? parseInt(income) : income) : 0
            }}</div>
          <div class="ms">累计收入</div>
        </div>
        <div class="dw_center" v-if="isBigCustom"></div>
        <div class="dw_left" v-if="isBigCustom">
          <div class="money">¥ {{ (bigNonArrivalIncome && isBelongCanAgent) ? ((isBelongCanAgent ==
      'y') ? parseInt(bigNonArrivalIncome) : bigNonArrivalIncome) : 0 }}</div>
          <div class="ms">未入账收益</div>
        </div>
      </div>
      <!-- 预分成商户显示数据 Begin -->
      <div class="dwbox" v-if="advance != null">
        <div class="dw_left">
          <div class="money">¥ {{ advance.targetAmount }}</div>
          <div class="ms">目标金额</div>
        </div>
        <div class="dw_center"></div>
        <div class="dw_left">
          <div class="money">{{ advance.completedProgress ? advance.completedProgress : 0.00 }}%</div>
          <div class="ms">完成比例</div>
        </div>
        <div class="dw_center"></div>
        <div class="dw_left">
          <div class="money">¥ {{ advance.completedAmount ? advance.completedAmount : 0.00 }}</div>
          <div class="ms">完成金额</div>
        </div>
      </div>
      <!-- 预分成商户显示数据 End -->
    </div>
    <div class="nav_bt">
      <div class="shu_blue"></div>
      <div>快捷方式</div>
    </div>
    <div class="fsbox">
      <button v-preventReClick class="fsinbox" v-for="(item, index) in imglist" :key="index" @click="routers(item)"
        v-show="item.show">
        <div class="fs_img">
          <img class="img_yuan" :src="item.img" />
          <!-- <div class="xx2"></div> -->
        </div>
        <div class="fs_title">{{ item.title }}</div>
      </button>
    </div>
    <div class="fsbox fsbox1" v-if="advArr && advArr.length > 0">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in advArr" :key="index" class="swipe-item" @click="swiperItemClick(item)">
          <img :src="item.fileUrl" class="swipe-img" />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import { getJson, postFormData, postJson } from '@/api/http';
import { Loading } from 'vant';
export default {
  data() {
    return {
      advArr: [],
      imglist: [
        {
          img: require("../assets/img/xx.png"),
          title: "商户信息",
          path: "/merchants",
          show: true,
        },
        {
          img: require("../assets/img/tx.png"),
          title: "提现",
          path: "/withdraw_money",
          show: true,
        },
        {
          img: require("../assets/img/mx.png"),
          title: "收益明细",
          path: "/benefit",
          show: true,
        },
        {
          img: require("../assets/img/dd.png"),
          title: "我的订单",
          path: "/myorder",
          show: false,
        },
        // { img: require("../assets/img/md.png"), title: "门店订单" ,path:""},
        {
          img: require("../assets/img/wx.png"),
          title: "绑定微信",
          path: "",
          show: true,
        },
        // {
        //   img: require("../assets/img/mf.png"),
        //   title: "申请免费账号",
        //   path: "/freeuse",
        //   show: true,
        // },
        // {
        //   img: require("../assets/img/xx.png"),
        //   title: "门店列表",
        //   path: "/storeList",
        //   show: true,
        // },
        {
          img: require("../assets/img/auth.png"),
          title: "实名信息",
          path: "/auth",
          show: true,
        },
        {
          img: require("../assets/img/tc.png"),
          title: "退出登录",
          path: "/login",
          show: true,
        },
      ],
      balance: 0,
      income: 0,
      merchant: {},
      isBelongCanAgent: 'y',//y 需要干掉小数点
      openbox: false,
      bigNonArrivalIncome: 0.00,
      // 预分成数据
      advance: null,
      // 是否为大客户门店
      isBigCustom: false
    };
  },
  // updated(){
  //   alert('更新')
  //   this.getlist()
  // },
  created() {
    let code = this.getUrlKey("code");
    if (code) {
      this.$http.bind({ code }).then((res) => {
        if (res.code == 200) {
          alert(res.msg)
          window.location.reload()
        } else {
          alert(res.msg)
        }
      }).catch(res => {
        alert('网络错误', res)
      })
    }
    this.$http.isBigCustom().then(res => {
      if (res.code == 200) {
        this.isBigCustom = res.isBigCustom;
        if (res.isBigCustom) {
          for (let a in this.imglist) {
            this.imglist[a].show = true
          }
        }
        this.isBelongCanAgent = this.isBigCustom ? 'n' : 'y'
        this.getDetails();
      }
    })
    var location = localStorage.getItem('xx-locatiion')
    if (!location) {
      this.requestAdv()

    } else {
      var geolocation = new qq.maps.Geolocation("ZTJBZ-RJKWV-4AUP5-55I6T-VQJTT-ZTBVD", "myapp");
      if (geolocation) {
        var geoloc = geolocation.getLocation(position => {
          if (position && position.lat) {
            var location = {
              lat: position.lat,
              lon: position.lng
            }
            localStorage.setItem('xx-locatiion', JSON.stringify(location))
          }
          this.requestAdv()
        });
      }

    }

  },
  mounted() {
    this.getlist()
    this.getBigNonArrivalIncome()
    this.requestSpecialAgent()//吴灿 手下
  },
  methods: {
    getlist() {
      this.$http.getInfo().then((res) => {
        this.balance = res.balance;
        this.merchant = res.merchant;
        localStorage.setItem('merchant', JSON.stringify(res.merchant))
        this.income = res.income;
      });
    },
    requestAdv() {
      var param = { place: '7' }
      var location = localStorage.getItem('xx-locatiion')
      if (location) {
        location = JSON.parse(location)
        param.lat = location.lat
        param.lon = location.lon

      }

      this.$http.getAdvertList(param).then(res => {
        if (res.code == 200) {
          if (res.data) {
            this.advArr = res.data
          }
        }

      })

    },
    routers(item) {
      if (item.title == '申请免费账号') {
        window.location.href = "https://gzh.heiqingting.net/freeuse"
        return
      }
      if (item.title == "退出登录") {
        localStorage.clear();
        this.$router.push('/login')
      } else if (item.title == "绑定微信") {
        if (this.merchant.weChat) {
          this.openbox = true;
        } else {
          var url = encodeURIComponent(window.location.href);
          var getCodeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5dd6d10a5324932b&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          window.location.href = getCodeUrl;
          console.log(encodeURIComponent(window.location.href));
        }
      }
      else {
        if (item.title == '收益明细') {
          item.path = '/Benefit?isBelongCanAgent=' + this.isBelongCanAgent
        }
        this.$router.push(item.path);
        console.log(item);
      }
    },
    swiperItemClick(item) {
      if (item.skipType == 1) {
        if (item.skipPath) {
          window.location.href = item.skipPath
        }
        return
      }
      if (item.skipType == 3) {
        if (item.skipPath) {
          this.$copyText(item.skipPath).then(
            function (e) {
              console.log("copy arguments e:", e);
              alert("复制成功!");
            },
            function (e) {
              console.log("copy arguments e:", e);
              alert("复制失败!");
            });
        }
        return
      }

    },

    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    /**
     * 获取大客户门店未到账收益
     */
    getBigNonArrivalIncome() {
      this.$http.getBigNonArrivalIncome().then((res) => {
        if (res.code === 200) {
          this.bigNonArrivalIncome = res.bigNonArrivalIncome;
        }
      });
    },
    requestSpecialAgent() {
      // this.$http.agentCheckSup({supId:'3669'}).then(res=>{
      //   if (res.code == 200){
      //     this.isBelongCanAgent = res.msg

      //   }
      // })

    },
    /**
     * 获取当前登录商户数据
     */
    getDetails() {
      let that = this;
      this.$http.getDetails().then((res) => {
        if (res.code == 200) {
          that.advance = res.advance;
          localStorage.setItem('xxMerchantInfo', JSON.stringify(res.merchant))
          if (that.advance != null) {
            that.$nextTick(() => {
              // 预分成商户是否能提现
              if (!that.advance.isWithdrawal) {
                for (let i = 0; i < that.imglist.length; i++) {
                  if (that.imglist[i].title == "提现") {
                    that.imglist[i].show = false;
                  }
                }
              }
              // 预分成商户是否能查看订单
              if (!that.advance.isOrderDetails) {
                for (let i = 0; i < that.imglist.length; i++) {
                  if (that.imglist[i].title == "我的订单") {
                    that.imglist[i].show = false;
                  }
                }
              }
            })
          }
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: white;
  border: 0px;
}

button :hover {
  background-color: rgba(0, 0, 0, 0.02)
}

.index {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;

  .banner {
    /* margin: 0 auto; */
    width: 100%;
    height: 156px;
    background: linear-gradient(180deg, #1890ff 0%, #35c3ff 100%);
    position: relative;

    .maintitle {
      margin: 0 auto;
      width: 375px;
      padding-top: 24px;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .wxnum {
      margin: 0 auto;
      width: 375px;
      padding-left: 20px;
      box-sizing: border-box;
      font-weight: bold;
      color: #ffffff;
      font-size: 16px;
      margin-top: 2px;
    }

    .dwbox {
      width: 335px;
      height: 104px;
      background: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      position: absolute;
      left: 50%;
      bottom: -52px;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;

      .dw_left {
        width: 167px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .money {
          font-weight: 600;
          color: #333333;
          font-size: 24px;
        }

        .ms {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #aaaaaa;
        }
      }

      .dw_center {
        width: 1px;
        height: 53px;
        background: #eee;
      }
    }
  }

  .nav_bt {
    width: 375px;
    margin: 0 auto;
    margin-top: 79px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    line-height: 16px;

    .shu_blue {
      width: 4px;
      height: 16px;
      background: #1890ff;
      border-radius: 2px;
      margin-left: 20px;
      margin-right: 8px;
    }
  }

  .fsbox {
    width: 335px;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 16px;
    padding: 6px 1px;
    box-sizing: border-box;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;

    .fsinbox {
      margin-left: 11px;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3px 0px 8px 0px;
      box-sizing: border-box;
      // background-color: red;
      margin-bottom: 1px;

      .fs_img {
        width: 43px;
        height: 52px;

        .img_yuan {
          width: 100%;
          height: 100%;
        }
      }

      .fs_title {
        margin-top: 0px;
        font-size: 12px;
        font-weight: bold;
        color: #333333;
      }
    }
  }

  .fsbox1 {
    padding: 0px;
    overflow: hidden;
    margin-bottom: 40px;

    .my-swipe {
      width: 100%;
      height: 190px;
    }

    .my-swipe .swipe-item {
      width: 100%;
      height: 100%;

    }

    .my-swipe .swipe-img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .fix_box {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .tx_box {
      width: 316px;
      height: 221px;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;

      .img_box {
        width: 80px;
        height: 80px;
        margin-top: 10px;
        border-radius: 50%;
        overflow: hidden;

        .imgin {
          width: 100%;
          height: 100%;
        }
      }

      .tx_title {
        font-size: 16px;
      }

      .tx_btn {
        width: 275px;
        height: 40px;
        background: linear-gradient(135deg, #35c3ff 0%, #1890ff 100%);
        border-radius: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }
  }
}
</style>